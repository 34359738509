import Vue from 'vue'
import HomePage from '../who_are_you.vue'
import ClientsSay from '../clients_say.vue'

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("who-are-you-section")
  if (element != null) {
    const app = new Vue({
      el: element,
      render: h => h(HomePage),
      data: {}
    }).$mount()
  }
})

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("what-clients-say-section")
  if (element != null) {
    const app = new Vue({
      el: element,
      render: h => h(ClientsSay),
      data: {}
    }).$mount()
  }
})
