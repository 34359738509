<template>
<section class="section" id="what-clients-say-section">
	<div class="level">
		<div class="level-item">
			<h2>What Clients Say</h2>
		</div>
	</div>
	<div class="level">
		<button @click="decrementCarousel()">
            <span class="icon is-medium center">
			    <i class="fas fa-arrow-left"></i>
		    </span>
        </button>
		<div class="tile is-ancestor">
			<div class="tile is-parent">
				<div v-if="carouselIndices.includes(1)" id="carousel-item-1" class="tile is-child is-6">
					<div class="card">
						<div class="card-content">
							<h1 class="orange-text">&quot;</h1>
							<div class="content is-quote">
							1Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Phasellus nec iaculis mauris.
							</div>

							<div class="media">
								<div class="media-left">
									<figure class="image is-48x48">
									<img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
									</figure>
								</div>
								<div class="media-content">
									<p class="title is-4">John Smith</p>
									<p class="subtitle is-6">@johnsmith</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="carouselIndices.includes(2)" id="carousel-item-2" class="tile is-child is-6">
					<div class="card">
						<div class="card-content">
							<h1 class="orange-text">&quot;</h1>
							<div class="content is-quote">
							2Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Phasellus nec iaculis mauris.
							</div>

							<div class="media">
								<div class="media-left">
									<figure class="image is-48x48">
									<img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
									</figure>
								</div>
								<div class="media-content">
									<p class="title is-4">John Smith</p>
									<p class="subtitle is-6">@johnsmith</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div v-if="carouselIndices.includes(3)" id="carousel-item-3" class="tile is-child is-6">
					<div class="card">
						<div class="card-content">
							<h1 class="orange-text">&quot;</h1>
							<div class="content is-quote">
							3Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Phasellus nec iaculis mauris.
							</div>

							<div class="media">
								<div class="media-left">
									<figure class="image is-48x48">
									<img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
									</figure>
								</div>
								<div class="media-content">
									<p class="title is-4">John Smith</p>
									<p class="subtitle is-6">@johnsmith</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div v-if="carouselIndices.includes(4)" id="carousel-item-4" class="tile is-child is-6">
					<div class="card">
						<div class="card-content">
							<h1 class="orange-text">&quot;</h1>
							<div class="content is-quote">
							4Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Phasellus nec iaculis mauris.
							</div>

							<div class="media">
								<div class="media-left">
									<figure class="image is-48x48">
									<img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
									</figure>
								</div>
								<div class="media-content">
									<p class="title is-4">John Smith</p>
									<p class="subtitle is-6">@johnsmith</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div v-if="carouselIndices.includes(5)" id="carousel-item-5" class="tile is-child is-6">
					<div class="card">
						<div class="card-content">
							<h1 class="orange-text">&quot;</h1>
							<div class="content is-quote">
							5Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Phasellus nec iaculis mauris.
							</div>

							<div class="media">
								<div class="media-left">
									<figure class="image is-48x48">
									<img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
									</figure>
								</div>
								<div class="media-content">
									<p class="title is-4">John Smith</p>
									<p class="subtitle is-6">@johnsmith</p>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div v-if="carouselIndices.includes(6)" id="carousel-item-6" class="tile is-child is-6">
					<div class="card">
						<div class="card-content">
							<h1 class="orange-text">&quot;</h1>
							<div class="content is-quote">
							6Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							Phasellus nec iaculis mauris.
							</div>

							<div class="media">
								<div class="media-left">
									<figure class="image is-48x48">
									<img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
									</figure>
								</div>
								<div class="media-content">
									<p class="title is-4">John Smith</p>
									<p class="subtitle is-6">@johnsmith</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button @click="incrementCarousel()">
            <span class="icon is-medium center">
			    <i class="fas fa-arrow-right"></i>
		    </span>
        </button>
	</div>
</section>

  
</template>

<script>
export default {
  data: function () {
    return {
      carouselItemIDs: [1,2,3,4,5,6],
      carouselIndices: [1,2]

    }
  },
  methods: {
    incrementCarousel: function(){
	  
	if(this.carouselIndices[1] == 6 ){
        this.carouselIndices[0] = 6;
        this.carouselIndices[1] = 1;
	  }   
	 if(this.carouselIndices[1] == 5) {
        this.carouselIndices[0]++;
        this.carouselIndices[1]++;
	  }
	if(this.carouselIndices[1] ==4) {
		this.carouselIndices[0]++;
        this.carouselIndices[1]++;
	}
	if(this.carouselIndices[1] ==3) {
		this.carouselIndices[0]++;
        this.carouselIndices[1]++;
	}
	if(this.carouselIndices[1] ==2) {
		this.carouselIndices[0]++;
        this.carouselIndices[1]++;
	}
	 if(this.carouselIndices[1] == 1 ){
        this.carouselIndices[0] = 1;
        this.carouselIndices[1] = 2;
	  } 
	  console.log(this.carouselIndices)
    },
    decrementCarousel: function(){
		
		if(this.carouselIndices[0] == 1 ) {
            this.carouselIndices[0] = 6;
            this.carouselIndices[1] = 1;
		} 
		if(this.carouselIndices[0] ==2) {
			this.carouselIndices[0]--;
        	this.carouselIndices[1]--;
		}
		if(this.carouselIndices[0] ==3) {
			this.carouselIndices[0]--;
        	this.carouselIndices[1]--;
		}
		if(this.carouselIndices[0] ==4) {
			this.carouselIndices[0]--;
        	this.carouselIndices[1]--;
		}
		if(this.carouselIndices[0] ==5) {
			this.carouselIndices[0]--;
        	this.carouselIndices[1]--;
		}
		if(this.carouselIndices[0] == 6 ) {
			this.carouselIndices[0] = 5;
			this.carouselIndices[1] = 6;
		}
		console.log(this.carouselIndices)
    }
  }
}
</script>