<template>
  <section class="section" id="who-are-you-section">
  	<div class="level">
  		<div class="level-item">
  			<h1 class="title has-text-centered is-size-4">Who are You?</h1>
  		</div>
  	</div>

    <div class="level">
      <div>
        <div class="columns is-vcentered">
          <div class="column is-one-fifth">
            <h1 class="title is-size-1">01</h1>
          </div>
          <div class="column">
              <h3 class="has-text-weight-bold">Veterinarian</h3>
              <p class="is-size-7">Created by Veterinarians for Veterinarians, our products streamline your processes with FDA compliant tools for VCPR, VFD, and Rx documentation and sharing. We understand that as you protect animal health, you are also protecting a business. Treat animals more efficiently, and when an auditor shows up - quickly provide records to prove compliance.</p>
          </div>
          <div class="column is-one-fifth has-text-right">
            <i class="fas fa-syringe is-size-1"></i>
          </div>
        </div>
        <hr>
        <div class="columns is-vcentered">
          <div class="column is-one-fifth">
            <h1 class="title is-size-1">02</h1>
          </div>
          <div class="column">
            <h3 class="has-text-weight-bold">Producer</h3>
            <p class="is-size-7">We thoroughly understand your responsibility for providing a safe, quality food supply that meets consumer’s expectations. One operation or many, our tools make your compliance with veterinarian prescriptions and feed directives easy, accurate, and efficient. Our advanced analytics tools provide the data you need to plan and execute profitable harvest schedules.</p>
          </div>
          <div class="column is-one-fifth has-text-right">
            <i class="fas fa-drumstick-bite is-size-1"></i>
          </div>
        </div>
        <hr>
        <div class="columns is-vcentered">
          <div class="column is-one-fifth">
            <h1 class="title is-size-1">03</h1>
          </div>
          <div class="column">
            <h3 class="has-text-weight-bold">Feed Distributor</h3>
            <p class="is-size-7">Each day’s demands require precise record management that is easy for you to use. Our tools provide you the real-time information you want with the compliance confidence you need. SimpleAg maintains a historical database of all records, simplifying your workflow and reporting needs.</p>
          </div>
          <div class="column is-one-fifth has-text-right">
            <i class="fas fa-truck is-size-1"></i>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProducerImage from 'images/we-have-the-meats.jpg';
import FeederImage from 'images/feed-ingredients.jpg';
import VetImage from 'images/vet.png';
export default {
  data: function () {
    return {
  	  producerImage: ProducerImage,
  	  feederImage: FeederImage,
  	  vetImage: VetImage,
    }
  },
}
</script>
